import { Item } from '~/domain/Item'
import styles from './index.module.scss'

interface NftCardBadgeProps {
    nft: Item
    className?: string
}

export const NftCardBadge: React.FC<NftCardBadgeProps> = ({ nft: item, className }) => {
  return (
    <>
      {
        item.isFragment() &&
          <span
            className={ `
              ${styles['nft-card-badge']}
              ${styles[`nft-card-badge--${className}`]}
              ${styles['nft-card-badge--fragment']}`
            }>
            <img
              className={ styles['nft-card-badge__image'] }
              src="/fragment.svg"
              alt="Fragment icon"
            />
            <span className={ styles['nft-card-badge__text'] }>
              { 'FRAGMENT' }
            </span>
          </span>
      }
      {
        item.isVault() &&
          <span
            className={ `
              ${styles['nft-card-badge']}
              ${styles[`nft-card-badge--${className}`]}
              ${styles['nft-card-badge--vault']}`
            }>
            <img
              className={ styles['nft-card-badge__image'] }
              src="/vault.svg"
              alt="Vault icon"
            />
            <span className={ styles['nft-card-badge__text'] }>
              { 'VAULT' }
            </span>
          </span>
      }
    </>
  )
}
