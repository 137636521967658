import { useContract, useNFT } from '@thirdweb-dev/react'
import { Collection } from '~/domain/Collection'
import { Item } from '~/domain/Item'
import { Vault } from '~/domain/Vault'

interface UseItemReturnType {
  data: Item | undefined
  error: unknown | undefined
  isLoading: boolean
  isError: boolean
}

export function useFullItem (collection: Collection, tokenId: string): UseItemReturnType {
  const { contract } = useContract(collection.contract.address, 'nft-collection')
  const { data: nft, error, isLoading, isError } = useNFT(contract, tokenId)
  let item: Item | undefined = undefined

  if (!isLoading && nft) {
    item = new Item(nft, collection, tokenId)
  }

  return { data: item, error, isLoading, isError }
}

export function useFragment (
  collection: Collection,
  tokenId: string,
  vault: Vault,
  fragmentId: string
): UseItemReturnType {
  const { contract } = useContract(vault.contract.address, 'nft-collection')
  const { data: nft, error, isLoading, isError } = useNFT(contract, fragmentId)
  let item: Item | undefined = undefined

  if (!isLoading && nft) {
    item = new Item(nft, collection, tokenId, vault, fragmentId)
  }

  return { data: item, error, isLoading, isError }
}

export function useVault (
  collection: Collection,
  tokenId: string,
  vault: Vault
): UseItemReturnType {
  const { contract } = useContract(collection.contract.address, 'nft-collection')
  const { data: nft, error, isLoading, isError } = useNFT(contract, tokenId)
  let item: Item | undefined = undefined

  if (!isLoading && nft) {
    item = new Item(nft, collection, tokenId, vault)
  }

  return { data: item, error, isLoading, isError }
}
