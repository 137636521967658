import React from 'react'
import styles from './index.module.scss'

export const NftCardLoading: React.FC = () => {
  return (
    <div className={ styles['nft-card__card'] }>
      <span className={ styles['nft-card__media'] }></span>
      <span className={ styles['nft-card__name'] }></span>
      <span className={ styles['nft-card__name--short'] }></span>
      <span className={ `${styles['nft-card__price']}` }></span>
    </div>
  )
}

export const CollectionsLoadingState: React.FC = () => {
  return (
    <div 
      className={ styles['nft-collection__container'] }
    >
      <NftCardLoading />
      <NftCardLoading />
      <NftCardLoading />
      <NftCardLoading />
      <NftCardLoading />
      <NftCardLoading />
      <NftCardLoading />
      <NftCardLoading />
    </div>
  )
}
