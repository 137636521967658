import { FC, PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styles from './index.module.scss'
import { ButtonSize, ButtonHierarchy } from '~/types/ButtonType'

interface LinkButtonProps {
  href?: string
  size?: ButtonSize
  hierarchy?: ButtonHierarchy
  icon?: IconProp
  className?: string
  isLoading?: boolean
  isDisabled?: boolean
  openInNewTab?: boolean
}

export const LinkButton: FC<PropsWithChildren<LinkButtonProps>> = (
  {
    href,
    icon,
    children,
    className,
    size = 'large',
    hierarchy = 'primary',
    isLoading,
    isDisabled,
    openInNewTab,
  }
) => {
  const CustomTag = href ? 'a' : 'span'

  return (
    <CustomTag
      className={ `
        ${className}
        ${styles['link-button']}
        ${hierarchy && styles[`link-button__${hierarchy}`]}
        ${size && styles[`link-button--${size}`]}
        ${isLoading && styles['link-button--loading']}
        ${isDisabled && styles['link-button--disabled']}
      ` }
      data-testid="link-button"
      href={ href }
      target={ openInNewTab ? '_blank' : '_self' }
    >
      { isLoading && !isDisabled &&
        <svg
          className={ styles['loader'] }
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1.25C10 0.559644 9.43819 -0.00805243
            8.75322 0.0780196C7.2172 0.271033
            5.74094 0.818913 4.4443 1.6853C2.79981
            2.78412 1.51808 4.3459 0.761205 6.17317C0.00432836
            8.00043 -0.193705 10.0111 0.192147 11.9509C0.577999 13.8907
            1.53041 15.6725 2.92893 17.0711C4.32746 18.4696 6.10929 19.422
            8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268
            19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.1811
            14.2591 19.729 12.7828 19.922 11.2468C20.0081 10.5618 19.4404 10 18.75
            10C18.0596 10 17.5106 10.5635 17.3961 11.2442C17.221 12.2848 16.8275 13.2816
            16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968
            9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.6967
            15.3033C3.64781 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083
            2.50325 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322
            3.76398C6.71839 3.17253 7.71517 2.77899 8.75576 2.60392C9.43655 2.48939
            10 1.94036 10 1.25Z"
          fill="#A3A5A8"/>
        </svg>
      }
      { children }

      { icon &&
        <FontAwesomeIcon
          className={ styles['link-button__icon'] }
          icon={ icon }
        />
      }
    </CustomTag>
  )
}
