import Head from 'next/head'
import { FC } from 'react'
import { siteName } from '~/constants/site'

interface HtmlPageMetadataProps {
  title: string
  description: string
  image: string
  url: string
}

export const HtmlPageMetadata: FC<HtmlPageMetadataProps> = (props) => {
  return (
    <Head>
      <title>{ props.title }</title>
      <meta name="description" content={ props.description } key="description" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="LEGEND Market, NFT, Sports, Digital Trophies, LEGEND Token"
      />
      <link rel="icon" href="/favicon.png" />
      <meta property="og:title" content={ props.title } key="og:title" />
      <meta property="og:description" content={ props.description } key="og:description" />
      <meta property="og:site_name" content={ siteName } key="og:site_name" />
      <meta property="og:type" content="website" key="og:type" />
      <meta property="og:locale" content="en" key="og:locale" />
      <meta property="og:url" content={ props.url } key="og:url" />
      <meta property="og:image" content={ props.image } key="og:image" />
    </Head>
  )
}
