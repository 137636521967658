import { NFT } from '@thirdweb-dev/sdk'
import { Collection } from './Collection'
import { Vault } from './Vault'

type ItemType = 'full-item' | 'vault' | 'fragment'

export class Item {
  constructor (
    public readonly nft: NFT,
    public readonly collection: Collection,
    public readonly tokenId: string,
    public readonly vault?: Vault,
    public readonly fragmentId?: string
  ) { }

  get type (): ItemType {
    return this.isFullItem()
      ? 'full-item'
      : (
        this.isFragment()
          ? 'fragment'
          : 'vault'
      )
  }

  get url (): string {
    return this.isFragment()
      ? `/series/${this.collection.seriesId}/${this.collection.contract.address}/${this.tokenId}` +
        `/${this.vault?.contract.address}/${this.nft.metadata.id}`
      : `/series/${this.collection.seriesId}/${this.collection.contract.address}/${this.nft.metadata.id}`
  }

  get vaultUrl (): string | undefined {
    return this.vault
      ? `/series/${this.collection.seriesId}/${this.collection.contract.address}/${this.tokenId}` +
        `/${this.vault?.contract.address}`
      : undefined
  }

  public isFullItem (): boolean {
    return this.vault === undefined && this.fragmentId === undefined
  }

  public isFragment (): boolean {
    return this.vault !== undefined && this.fragmentId !== undefined
  }

  public isVault (): boolean {
    return this.vault !== undefined && this.fragmentId === undefined
  }
}
