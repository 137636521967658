import { DirectListingV3 } from '@thirdweb-dev/sdk'

/**
 * Multiple active listings available, choose the one with the best price.
 * @param listings Must have at least one listing.
 * @returns Listing with the lowest price.
 * @todo Consider multiple contracts (currencies) might be used
 */
export const findBestActiveDirectListing = (listings: DirectListingV3[]): DirectListingV3 => {
  return listings.reduce((previous, current) => {
    return previous.currencyValuePerToken.value.lt(current.currencyValuePerToken.value)
      ? previous
      : current
  })
}
