export const marketplaceContractAddress = (chain: number) => {
  switch (chain) {
  case 338: // Cronos Testnet
    return '0x4C787c0068A8F9cFA512A2a70A8A9F754D48D50f'

  default:
    return String(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT)
  }
}

export const fragmentsVaultFactoryContractAddress = String(process.env.NEXT_PUBLIC_FRAGMENTS_VAULT_FACTORY_CONTRACT)

export const fragmentsDropVaultFactoryContractAddress = String(
  process.env.NEXT_PUBLIC_FRAGMENTS_DROP_VAULT_FACTORY_CONTRACT
)

export const vaultSubgraphEndpoint = String(process.env.NEXT_PUBLIC_VAULT_SUBGRAPH_ENDPOINT)

export const dropVaultSubgraphEndpoint = String(process.env.NEXT_PUBLIC_DROP_VAULT_SUBGRAPH_ENDPOINT)
